/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


h1 {
  font-size: 3rem;
}
h2 {
  font-weight: 200;
  font-size: 2.25rem;
}
h3 {
  font-weight: 200;
  font-size: 1.755rem;
}
h4 {
  font-size: 1.5rem;
}

p {
  font-size: 1.2rem;
}


ion-button {
  font-size: 1rem;
  font-weight: bold;
  text-transform: none;
  --border-radius: 0;

  @media screen and (max-width: 576px) {
    font-size: 0.9rem;
  }
}

ion-popover {
  --min-height: 400px;
  --min-width: 400px;
  --height: 400px;
  --width: 400px;
}
.sc-ion-popover-md-h {
  --width: 80vw;
  --max-width: 500px;
}

.logo {
  max-width: 140px;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 120px;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3rem;
  width: calc(100vw - 6rem);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.left {
    justify-content: flex-start;
    .logo {
      margin: 0;
    }
  }
}

.container {
  max-width: 992px;
  margin: 0 auto;
  padding: 1rem;

  &.no-padding {
    padding: 0;
  }
}

.center {
  text-align: center;
}

.description {
  a {
    color: #2a343d;
    // font-weight: bold;
  }
}

ion-item {
  ion-label.label-floating.sc-ion-label-md-h,
  .label-stacked.sc-ion-label-md-h {
    margin-bottom: 0.5rem;
  }
}
.color-primary {
  color: var(--ion-color-primary);
}

.no-margin {
  margin: 0;
}

.circle {
  background: #fff;
  color: var(--ion-color-primary);
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.violet {
    color: var(--violet);
    background: white;
  }

  &.dark-violet {
    color: var(--dark-violet);
    background: white;
  }

  &.dark-violet-inversed {
    color: white;
    background: var(--dark-violet);
  }

  .data {
    font-size: 4rem;
    font-weight: bold;
  }
  .text {
    font-size: 1.2rem;
    padding: 0 1.5rem;
    font-weight: 600;
  }
}

.wrap-button {
  white-space: pre-wrap;

  &:last-child {
    margin-top: 1rem;
  }
}

.medium-text {
  font-size: 1.5rem;
}

ion-col.ion-text-right {
  padding-right: 1rem;
}

plan-selection.ion-page {
  overflow: scroll;
}

ion-header.onboarding {
  ion-toolbar {
    --min-height: 6rem;
    --background: #fff;
    --border-style: none;
    color: black;
    max-width: 100vw;

    .login-logo {
      max-height: 100%;
    }

    .logo {
      //max-width: 40vw;
      margin: 0 auto;
    }
  }
  ion-back-button {
    --icon-font-size: 2rem;
    margin-left: 1rem;
  }
}

.large-modal .modal-wrapper {
  height: 80%;
  top: 10%;
  position: absolute;
  display: block;
  width: 80%;
  max-width: 800px;

  @media screen and (max-width: 500px) {
    height: 100%;
    width: 100%;
    top: 0;
  }
}

.right-align {
  text-align: right;
}

.small-paragraph {
  font-size: 1rem;
}

.large-padding-top {
  padding-top: 5rem;

  @media (max-width: 768px) {
    padding-top: 2rem;
  }
}

.small-padding-top {
  padding-top: 2rem;
}

ion-toolbar {
  overflow: hidden;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

ion-card-subtitle {
  text-transform: none;
}

.background {
  &--primary {
    background: var(--ion-color-primary);
  }
  &--secondary {
    background: var(--ion-color-secondary);
  }
  &--light {
    background: var(--ion-color-light);
  }
  &--medium {
    background: var(--ion-color-medium);
  }
  &--dark {
    background: var(--ion-color-dark);
  }
  &--warning {
    background: var(--ion-color-warning);
  }
  &--danger {
    background: var(--ion-color-danger);
  }
  &--light-green {
    background: #f2fafa;
  }
}

.text {
  &--primary {
    color: var(--ion-color-primary);
  }
  &--secondary {
    color: var(--ion-color-secondary);
  }
  &--light {
    color: var(--ion-color-light);
  }
  &--medium {
    color: var(--ion-color-medium);
  }
  &--dark {
    color: var(--ion-color-dark);
  }
  &--orange {
    color: var(--migros-color) !important;
  }
  &--danger {
    color: var(--ion-color-danger);
  }
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--small {
    font-size: 0.9rem;
  }
  &--normal {
    font-size: 1rem;
  }
  &--bold {
    font-weight: bold;
  }
}

.primary-color {
  color: var(--ion-color-primary);
}

ion-card {
  border-radius: 0;
}

hr {
  border-width: 1px;
  height: 0;
  border-color: var(--ion-color-primary);
  border-style: dashed;
}

.triangle-bottom-right {
  pointer-events: none;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 200px 150vw;
  border-color: transparent transparent var(--ion-color-primary) transparent;
  opacity: 0.9;
  position: absolute;
  top: -200px;
  z-index: 101;
  right: -30vw;

  @media screen and (max-width: 768px) {
    border-width: 0 0 100px 150vw;
    top: -100px;
  }

  &.secondary-border-color {
    border-color: transparent transparent var(--ion-color-secondary) transparent;
  }
  &.tertiary-border-color {
    border-color: transparent transparent var(--ion-color-tertiary) transparent;
  }
}
.triangle-bottom-left {
  pointer-events: none;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 200px 0 0 200vw;
  border-color: transparent transparent transparent var(--ion-color-primary);
  opacity: 0.9;
  position: absolute;
  top: -130px;
  right: -70vw;
  z-index: 101;
  &.secondary-border-color {
    border-color: transparent transparent transparent var(--ion-color-secondary);
  }
  &.tertiary-border-color {
    border-color: transparent transparent transparent var(--ion-color-tertiary);
  }

  @media screen and (max-width: 768px) {
    border-width: 100px 0 0 200vw;
    top: -65px;
  }
}

.triangle-top-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200vw 200px 0;
  border-color: transparent var(--ion-color-primary) transparent transparent;
  opacity: 0.9;
  position: absolute;
  bottom: -200px;
  right: -70vw;
  z-index: 101;

  &.secondary-border-color {
    border-color: transparent var(--ion-color-secondary) transparent transparent;
  }
  &.tertiary-border-color {
    border-color: transparent var(--ion-color-tertiary) transparent transparent;
  }
}

.triangle-top-left {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 200px 200vw 0 0;
  border-color: var(--ion-color-primary) transparent transparent transparent;
  opacity: 0.9;
  position: absolute;
  bottom: -130px;
  right: -70vw;
  z-index: 101;
  &.secondary-border-color {
    border-color: var(--ion-color-secondary) transparent transparent transparent;
  }
  &.tertiary-border-color {
    border-color: var(--ion-color-tertiary) transparent transparent transparent;
  }
}

ion-row {
  position: relative;
}

.relative {
  position: relative;
}

.zindex200 {
  z-index: 200;
}

.triangle-top {
  margin-top: 7rem;
}

.swiper-pagination {
  z-index: 500;
}

.big-bullets {
  .swiper-pagination-bullets {
    bottom: 8rem !important;
    .swiper-pagination-bullet {
      --bullet-background: var(--ion-color-medium);
      width: 24px;
      height: 24px;
      opacity: 0.5;

      &.swiper-pagination-bullet-active {
        border: 2px solid var(--ion-color-light);
        opacity: 1;
      }
    }
  }
}


footer {
  width: 100%;
}

.chat-spacer {
  margin-bottom: 4rem;
}

.margin {
  &--bottom_small {
    margin-bottom: 0.5rem;
  }
  &--bottom {
    margin-bottom: 1rem;
  }
  &--bottom_large {
    margin-bottom: 3rem;
  }
  &--top_small {
    margin-top: 0.5rem;
  }
  &--top {
    margin-top: 1rem;
  }
  &--no-top {
    margin-top: 0;
  }
  &--no-bottom {
    margin-bottom: 0;
  }
  &--top_large {
    margin-top: 3rem;
  }
  &--right {
    margin-right: 1rem;
  }
}

.tile {
  border: 2px solid #7fc9d3;
  background-color: #f2fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

ion-button.icon {
  margin-bottom: 3px;
}

.float-right {
  float: right;
}

.small-modal {
  --overflow: visible;
  --max-height: 300px;
  --height: 300px;
  padding: 0 1rem;
  --backdrop-opacity: 0.25;
  --background: transparent;
  --box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.pointer {
  cursor: pointer;
}

h4 {
  font-weight: normal;
  margin-top: 0;
}

.padding {
  &--no-bottom {
    padding-bottom: 0 !important;
  }
}

.divider {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
